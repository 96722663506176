import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col, Container } from "react-bootstrap"
import Axios from "axios"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Navbar, Nav } from "react-bootstrap"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      test: false,
      selectedPostImage: null,
      showContact: false,
    }
    this.MouseOver = this.MouseOver.bind(this);
    this.MouseOut = this.MouseOut.bind(this);
    this.contactReveal = this.contactReveal.bind(this);
    this.contactHide = this.contactHide.bind(this);
  }

  contactReveal() {
    this.setState({showContact: true})
  }

  contactHide() {
    this.setState({showContact: false})
  }

  MouseOver(featImage) {
    this.setState({test: false})
    this.setState({selectedPostImage: featImage})
    setTimeout(() => {
        this.setState({ test: true });
    }, 1000);
  }

  MouseOut(featImage){
    this.setState({selectedPostImage: null})
    setTimeout(() => {
        this.setState({ test: false });
    }, 1000);
  }

  render(){
    return(
      <Layout siteInfo={this.props.data.prismicSiteinfo.data} menu={this.props.data.allPrismicMenu.nodes}>
        <SEO title="IndexPage" />
        <Row noGutters className="min-vh-100 index-banner_message-row justify-content-center align-self-center">
          <Col md={12} className="my-auto">
            <h1 className="index-banner_message display-1">{this.props.data.prismicSiteinfo.data.banner_message.text}</h1>
          </Col>
        </Row>

        <Row noGutters className="index-about_me-row">
          <Col md={4}>
            <h1 className="index-about_me-title display-1">About Me</h1>
          </Col>
          <Col md={4}>
            <p className="index-about_me-content">{this.props.data.prismicSiteinfo.data.about_me.text}</p>
          </Col>
          <Col md={4}></Col>
        </Row>

        <Row noGutters className="index-blogpost-row">
          <Col md={12}>
            <h1 className="index-blogpost-title display-1 float-right">Latest Blog Posts</h1>
          </Col>
          <Col md={12}>
            <p className="float-right index-blogpost-read-more">read more...</p>
          </Col>
          <Col md={6} className={this.state.test ? "index-blogpost-item-featured_image-op" : "index-blogpost-item-featured_image"} >
            {
              this.state.selectedPostImage !== null &&
              <GatsbyImage image={this.state.selectedPostImage}/>
            }
          </Col>
          <Col md={6}>
            {this.props.data.allPrismicPost.nodes.map((post) =>
              <Row noGutters className="index-blogpost-item-row" onMouseOver={() => this.MouseOver(post.data.featured_image.gatsbyImageData)} onMouseOut={() => this.MouseOver(null)}>
                <Col md={12}>
                  <h4 className="d-inline-block">{post.data.post_title.text}</h4>
                  <p className="d-inline-block float-right">{post.data.date}</p>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Row noGutters className="index-contact-row">
          <Col md={12}>
            <h1 className="index-contact-title display-1" onMouseOver={this.contactReveal} onMouseOut={this.contactHide}>{this.state.showContact ? this.props.data.prismicSiteinfo.data.contact : "Let's Talk..."} </h1>
          </Col>
        </Row>
      </Layout>
    )
  }
}

export default IndexPage

export const indexQuery = graphql`
query MyQuery {
  prismicSiteinfo {
    data {
      contact
      website_name {
        text
      }
      banner_message {
        text
      }
      about_me {
        text
      }
    }
  }
  allPrismicMenu {
    nodes {
      data {
        menu_link
        menu_title
      }
    }
  }
  allPrismicPost(limit: 5) {
    nodes {
      data {
        post_title {
          text
        }
        featured_image {
          gatsbyImageData(placeholder: DOMINANT_COLOR, imgixParams: {sepia: 10}, height: 500)
        }
        date(formatString: "DD-MM-YYYY")
      }
    }
  }
}
`
